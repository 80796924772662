export default {
  svgAttrs: {
    width: null,
    height: null,
    viewBox: "0 0 570.41 574.2"
  },
  shapesWeights: [
    [ 5, 4, 2, 2, 1, 1.5, 2.5, 2, 1.5, 2, 2, 1.5, 2, 2.5, 3, 2, 2, 2, 1, 1, 1, 2, 3, 4 ],
    [ 0.5, 0.8, 1.3, 1.3, 1.3, 1.3, 0.8, 0.5, 0.5, 0.5, 0.5, 0.6, 0.8, 0.4, 0.4, 0.5, 0.6, 0.7, 0.5, 0.5, 0.4, 0.4 ]
  ],
  shapeStrings: [
    "M7.16,231c28.07-42.67,102.62-53.65,189-95,94-45,121-104,178-107,32.58-1.71,53.07,29.24,67.45,64.11,10.78,26.14,18.12,54.47,24.55,72.89,15,43,46,125,59,159s2,85-8,98-1,0-18,19-22,50-44,57-46.84-1.75-88,0c-47,2-115,13-178,27-59.73,13.27-149,7.4-162-36-12-40,18-78,27-89s118-121,151-160c32.43-38.33,92-129,125-153,18-13.06,67-16,94-7s24.19,25.19,44,45c13,13,34,16,41,33,9.37,22.75,12.51,80.66,16,110,5,42,28,98,22,123s-20,41-120,52-127,47-204,45-177-52-179-73c-2.71-28.51,10.38-39,11-64C56.16,312-17.84,269,7.16,231Z",
    "M55.16,11c35-25,60,9,144,18s161-6,187,9,43,87,61,119c9.66,17.17,50,40,59,73,6.08,22.27,5,107-22,151s-76,59-120.76,81c-55.24,27-73.85,62.94-107.24,70-47,10-98-120-115-158s-90-156-89-183,7-29,8-45-11.24-49.16-7-58,31-46,61-46,162,76,197,90,248,80,259,164-54,115-70,129-51.92,42.92-76,67c-27,27-37.67,69.57-58,81-16,9-34.82-11.8-52.41-25.4s-34.56-9.05-50.59-16.6c-17-8-57-52-78-94s-61-164-77-204C93.36,196,17.12,38.19,55.16,11Z"
  ]
}
export const shapesWeights = [
  [ 5, 4, 2, 2, 1, 1.5, 2.5, 2, 1.5, 2, 2, 1.5, 2, 2.5, 3, 2, 2, 2, 1, 1, 1, 2, 3, 4 ],
  [ 0.5, 0.8, 1.3, 1.3, 1.3, 1.3, 0.8, 0.5, 0.5, 0.5, 0.5, 0.6, 0.8, 0.4, 0.4, 0.5, 0.6, 0.7, 0.5, 0.5, 0.4, 0.4 ]

]
export const shapeStrings = [
  "M7.16,231c28.07-42.67,102.62-53.65,189-95,94-45,121-104,178-107,32.58-1.71,53.07,29.24,67.45,64.11,10.78,26.14,18.12,54.47,24.55,72.89,15,43,46,125,59,159s2,85-8,98-1,0-18,19-22,50-44,57-46.84-1.75-88,0c-47,2-115,13-178,27-59.73,13.27-149,7.4-162-36-12-40,18-78,27-89s118-121,151-160c32.43-38.33,92-129,125-153,18-13.06,67-16,94-7s24.19,25.19,44,45c13,13,34,16,41,33,9.37,22.75,12.51,80.66,16,110,5,42,28,98,22,123s-20,41-120,52-127,47-204,45-177-52-179-73c-2.71-28.51,10.38-39,11-64C56.16,312-17.84,269,7.16,231Z",
  "M55.16,11c35-25,60,9,144,18s161-6,187,9,43,87,61,119c9.66,17.17,50,40,59,73,6.08,22.27,5,107-22,151s-76,59-120.76,81c-55.24,27-73.85,62.94-107.24,70-47,10-98-120-115-158s-90-156-89-183,7-29,8-45-11.24-49.16-7-58,31-46,61-46,162,76,197,90,248,80,259,164-54,115-70,129-51.92,42.92-76,67c-27,27-37.67,69.57-58,81-16,9-34.82-11.8-52.41-25.4s-34.56-9.05-50.59-16.6c-17-8-57-52-78-94s-61-164-77-204C93.36,196,17.12,38.19,55.16,11Z"
  //"M55.16,11c35-25,60,9,144,18s161-6,187,9,43,87,61,119c9.66,17.17,50,40,59,73,6.08,22.27,5,107-22,151s-76,59-120.76,81c-55.24,27-73.85,62.94-107.24,70-47,10-98-120-115-158s-90-156-89-183,7-29,8-45-11.24-49.16-7-58,29.07-52.89,61-46c39.34,8.49,162,76,197,90s248,80,259,164-54,115-70,129-51.92,42.92-76,67c-27,27-37.67,69.57-58,81-16,9-34.82-11.8-52.41-25.4s-34.56-9.05-50.59-16.6c-17-8-57-52-78-94s-61-164-77-204C93.36,196,17.12,38.19,55.16,11Z"
  ];

const original = `
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="570.41" height="574.2" viewBox="0 0 570.41 574.2">
  <path d="M7.16,231c28.07-42.67,102.62-53.65,189-95,94-45,121-104,178-107,32.58-1.71,53.07,29.24,67.45,64.11,10.78,26.14,18.12,54.47,24.55,72.89,15,43,46,125,59,159s2,85-8,98-1,0-18,19-22,50-44,57-46.84-1.75-88,0c-47,2-115,13-178,27-59.73,13.27-149,7.4-162-36-12-40,18-78,27-89s118-121,151-160c32.43-38.33,92-129,125-153,18-13.06,67-16,94-7s24.19,25.19,44,45c13,13,34,16,41,33,9.37,22.75,12.51,80.66,16,110,5,42,28,98,22,123s-20,41-120,52-127,47-204,45-177-52-179-73c-2.71-28.51,10.38-39,11-64C56.16,312-17.84,269,7.16,231Z" transform="translate(-1.5 -1.5)" fill="none" stroke="#35a012" stroke-miterlimit="10"/>
  <path d="M55.16,11c35-25,60,9,144,18s161-6,187,9,43,87,61,119c9.66,17.17,50,40,59,73,6.08,22.27,5,107-22,151s-76,59-120.76,81c-55.24,27-73.85,62.94-107.24,70-47,10-98-120-115-158s-90-156-89-183,7-29,8-45-11.24-49.16-7-58,31-46,61-46,162,76,197,90,248,80,259,164-54,115-70,129-51.92,42.92-76,67c-27,27-37.67,69.57-58,81-16,9-34.82-11.8-52.41-25.4s-34.56-9.05-50.59-16.6c-17-8-57-52-78-94s-61-164-77-204C93.36,196,17.12,38.19,55.16,11Z" transform="translate(-1.5 -1.5)" fill="none" stroke="#35a012" stroke-miterlimit="10"/>
</svg>
`